@tailwind base;
@tailwind components;
@tailwind utilities;

.logo {
  display: flex;
  align-items: center;
  font-size: x-large;
}

.clip-contour {
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.animate-bounce {
  animation: bounce 1s infinite;
}

.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.min-h-screen {
  min-height: 100vh;
}
.text-2xl {
  font-size: 1.5rem; /* Tailwind CSS font size */
}
.text-center {
  text-align: center;
}
.text-gray-800 {
  color: #2d3748; /* Dark gray for better readability */
}

/* index.css or App.css */
.sidebar-link {
  display: flex;
  align-items: center;
  padding: 10px;
  color: white;
  transition: background 0.3s;
}

.sidebar-link:hover {
  background: rgba(255, 255, 255, 0.1);
}

.image-preview {
  max-height: 300px; /* Set a maximum height */
  width: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Ensure the image covers the container without stretching */
  display: block; /* Prevent inline spacing issues */
  margin: 0 auto; /* Center the image if necessary */
}
